import { generalFunctions } from "./components/general-functions";
import { externalLinks } from "./components/external-links";
// import * as leaflet from "../../../node_modules/leaflet/dist/leaflet-src";

generalFunctions();

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable no-new, no-restricted-syntax */
  externalLinks();
  // leaflet();
});
